import React, { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import Toaster from 'components/snaq-ui/toaster';
import { FrontasticProvider } from 'frontastic';
import '../styles/app.scss';

function FrontasticStarter({ Component, pageProps }: AppProps) {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      // console.log(`App is changing to ${url} ${shallow ? 'with' : 'without'} shallow routing`);
      document.body.style.cursor = 'progress';
      return;
    };

    const handleRouteComplete = (url, { shallow }) => {
      // console.log('you have finished going to the new page');
      document.body.style.cursor = '';
      return;
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteComplete);
    };
  }, [router]);

  // https://nextjs.org/docs/messages/react-hydration-error#solution-1-using-useeffect-to-run-on-the-client-only
  // https://www.joshwcomeau.com/react/the-perils-of-rehydration/#the-solution-9
  if (!hasMounted) {
    return null;
  }

  return (
    <FrontasticProvider>
      <Component {...pageProps} />
      {process.env.NODE_ENV === 'production' && (
        <>
          {/* NOTE: Cookiebot script used via GTM below
          <Script
            id="Cookiebot"
            src="https://consent.cookiebot.com/uc.js"
            data-cbid="cf9fa8f5-6fa0-4ac0-9d0f-5ff3a3e2c75a"
            data-blockingmode="auto"
          />
          */}
          <Script
            id="GoogleTagManager"
            dangerouslySetInnerHTML={{
              __html: `(function (w, d, s, l, i) {
                      w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                      var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                      j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
                  })(window, document, 'script', 'dataLayer', 'GTM-NZGF2JZN');`,
            }}
          />
          <Script
            id="SalesforceTracking"
            dangerouslySetInnerHTML={{
              __html: `piAId = '1058023'; piCId = '157444'; piHostname = 'live.snaq.com';
                (function() {
                  function async_load() {
                    var s = document.createElement('script');
                    s.type = 'text/javascript';
                    s.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + piHostname + '/pd.js';
                    var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
                  }
                  if (window.attachEvent) { window.attachEvent('onload', async_load); } else { window.addEventListener('load', async_load, false); }
                })();`,
            }}
          />
        </>
      )}
      <Toaster />
    </FrontasticProvider>
  );
}

export default appWithTranslation(FrontasticStarter);
